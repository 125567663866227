import User from "../api/User";
import {isMobileDevice} from '@ksbteam/core/api/MobileDevice';
import Cookies from "universal-cookie";

const initialState = {
    drawerLeft: {open: false},
    isMobileDevice: isMobileDevice(),
    User: new User(),
    dataProcessing: false,
    notifications: [],
    errorMessage: {},
    servers: [
        {label: 'Roscongress', value: 'https://sum1rst.roscongress.org/'},
        {label: 'Dev', value: 'https://rst-dev.sum1.ru/'},
        {label: 'Icr2 Forms Dev', value: 'https://rst-dev.sum1.ru/', project: "icr2forms"},
        {label: 'Service Desc', value: 'https://sum1rst.roscongress.org/', project: "service_desc"},
        {label: 'Service Desc Dev', value: 'https://rst-dev.sum1.ru/', project: "service_desc"},
        {label: 'ICR2APP', value: 'https://rst.sum1.ru/'},
        {label: 'RC Voting', value: 'https://sum1rst.roscongress.org/', project: "icr2_polls"},
        {label: 'Polls Test', value: 'https://rst-dev.sum1.ru/', project: "icr2_polls_test"},
        {label: 'ICR2 T1', value: 'https://rst-dev.sum1.ru/', project: "icr2_t1"},
        // {label: 'Polls Test', value: 'https://polls-test.sum1.ru/'},
        // {label: 'WFYS', value: 'https://sum1wfyrst.roscongress.org/'},
        // {label: 'WFYS Test', value: 'https://wfyrst.test.sum1.ru/'},
        // {label: 'Test 2', value: 'https://test-uk-rst.sum1.ru/'},
    ],
    currentServer: undefined
};

export function page(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'PAGE_TOGGLE_DRAWER_LEFT':
            return Object.assign({}, state, {
                drawerLeft: {
                    open: !state.drawerLeft.open
                }
            });
        case 'PAGE_UPDATE_USER':
            return Object.assign({}, state, {
                User: new User()
            });
        case 'GET_CURRENT_SERVER':
            const serverOnCookie = (new Cookies())?.get('services');
            let currentServer = state.servers[0];

            if (serverOnCookie) {
                currentServer = state.servers.find((server) => server.value === serverOnCookie.restClient && server.label === serverOnCookie.server) ?? state.servers[0];
            }

            return Object.assign({}, state, {currentServer});
        case 'PAGE_SET_DATA_PROCESSING':
            return Object.assign({}, state, {
                dataProcessing: action.dataProcessing
            });
        case 'PAGE_SET_ERROR':
            return Object.assign({}, state, {
                errorMessage: action.errorMessage
            });
        case 'ENQUEUE_SNACKBAR':
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.key,
                        ...action.notification,
                    },
                ],
            };
        case 'CLOSE_SNACKBAR':
            return {
                ...state,
                notifications: state.notifications.map(notification => (
                    (action.dismissAll || notification.key === action.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )),
            };

        case 'REMOVE_SNACKBAR':
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            };
        default:
            return state;
    }
}
