import React from 'react';
import {Provider} from 'react-redux';
import { SnackbarProvider } from 'notistack';
import Page from './Page';
import {getUrlParams} from "@ksbteam/core/api/UrlManager";
import Cookies from 'universal-cookie';
import store from './makeStore';


export default class App extends React.Component {
    constructor(props) {
        super(props);
        const params = getUrlParams();
        const cookies = new Cookies();

        if (!!params.token && !!params.token.length) {
            cookies.set('authToken', params.token, {path: '/'});
        }
    }
    render() {
        return (
            <Provider store={store}>
                <SnackbarProvider maxSnack={5}>
                    <Page/>
                </SnackbarProvider>
            </Provider>
        );
    }
}
