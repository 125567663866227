import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

const StyledLoading = styled('div')(() => ({
    width: '100%',
    height: '100%',
    minHeight: '100px',
    maxHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
        width: 'auto',
        height: '25px',
    },
    "& .circleProgress": {
        position: 'absolute',
        left: -7,
        right: 0,
        top: 'calc(50% - 23px)',
        color: '#ef7d00'
    }
}))

const Loading = () => {

    return (
        <StyledLoading>
            <Box position="relative">
                <img src="/logo_square.png" alt="" />
                <CircularProgress className="circleProgress" color={'inherit'}/>
            </Box>
        </StyledLoading>
    )
}

export default Loading
