import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {language} from '@ksbteam/core/api/DummyTranslate';
import HttpApi from "i18next-http-backend";

i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        lng: language,
        fallbackLng: "ru",
        interpolation: {
            escapeValue: false
        },
        backend: {
            queryStringParams: {
                v: import.meta.env.VITE_LANG_VERSION
            }
        }
    });

window.i18n_inst = i18n;

export default i18n;