import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    typography: {
        h1: {
            fontSize: 32, //37 - в макете
            lineHeight: 1.15
        },
        h2: {
            fontSize: 25,
        },
        body1: {
            fontSize: 14,
        },
        body2: {
            fontSize: 16, //18 - в макете
        },
    },
    palette: {
        secondary: {
            light: '#ab0d28',
            main: '#a50e2d',
        },
        text: {primary: '#5a5a5a'},
        background: {
            default: '#fff'
        }
    }
});

export default theme;
