const initialState = {
    open: false,
    deleteOpen: false,
    configOpen: false,
    objTypeId: null,
    objTypeData: {},
    attrsDialog: false,
    attrId: null,
    attrData: {},
    attrDeleteOpen: false,
    roleDialog: false,
    roleId: null,
    roleData: {}
};

export function objtype(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'OBJTYPE_SET_ROLE_ID':
            return Object.assign({}, state, {
                roleId: action.roleId
            });
        case 'OBJTYPE_SET_ROLE_DIALOG':
            return Object.assign({}, state, {
                roleDialog: action.roleDialog
            });
        case 'OBJTYPE_SET_ROLE_DATA':
            return Object.assign({}, state, {
                roleData: action.roleData
            });
        case 'OBJTYPE_SET_OPEN':
            return Object.assign({}, state, {
                open: action.open
            });
        case 'OBJTYPE_SET_DELETE_OPEN':
            return Object.assign({}, state, {
                deleteOpen: action.deleteOpen
            });
        case 'OBJTYPE_SET_CONFIG_OPEN':
            return Object.assign({}, state, {
                configOpen: action.configOpen
            });
        case 'OBJTYPE_SET_ID':
            return Object.assign({}, state, {
                objTypeId: action.objTypeId
            });
        case 'OBJTYPE_SET_DATA':
            return Object.assign({}, state, {
                objTypeData: action.objTypeData
            });
        case 'OBJTYPE_SET_ATTRS_DIALOG':
            return Object.assign({}, state, {
                attrsDialog: action.attrsDialog
            });
        case 'OBJTYPE_SET_ATTRS_ID':
            return Object.assign({}, state, {
                attrId: action.attrId
            });
        case 'OBJTYPE_SET_ATTRS_DATA':
            return Object.assign({}, state, {
                attrData: action.attrData
            });
        case 'OBJTYPE_SET_ATTR_DELETE_OPEN':
            return Object.assign({}, state, {
                attrDeleteOpen: action.attrDeleteOpen
            });
        default:
            return state;
    }
}
