const initialState = {
    usersConfig: {},
    userRolesConfig: {},
    rolesDialog: false,
    rolesUserId: null,
    userPasswordDialog: false,
    passwordForm: {}
};

export function usersnroles(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'USERSROLES_SET_USERS_CONFIG':
            return Object.assign({}, state, {
                usersConfig: action.usersConfig
            });
        case 'USERSROLES_SET_USER_ROLES_CONFIG':
            return Object.assign({}, state, {
                userRolesConfig: action.userRolesConfig
            });
        case 'USERSROLES_SET_ROLES_DIALOG':
            return Object.assign({}, state, {
                rolesDialog: action.rolesDialog
            });
        case 'USERSROLES_SET_ROLES_USER_ID':
            return Object.assign({}, state, {
                rolesUserId: action.rolesUserId
            });
        case 'USERSROLES_SET_USER_PASSWORD_DIALOG':
            return Object.assign({}, state, {
                userPasswordDialog: action.userPasswordDialog
            });
        case 'USERSROLES_SET_USER_PASSWORD_FORM':
            return Object.assign({}, state, {
                passwordForm: action.passwordForm
            });
        default:
            return state;
    }
}
