import Cookies from 'universal-cookie';
import RestClient from '@ksbteam/core/api/RestClient';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { language } from '@ksbteam/core/api/DummyTranslate';

const cookies = new Cookies();

export default class User {
    constructor() {
        this._authToken = cookies.get('authToken');
        this._twoStepAuth = cookies.get('twoStepAuth');
        this._userInfo = cookies.get('icr2user');
        // this._fingerPrint = cookies.get('fingerPrint');
        // if (!this._fingerPrint){
        this.generateFingerprint().then(fp => this._fingerPrint = fp);
        // }
        this.initRestClient();
        this.gridSettingsBaseUrl = 'settings/grid-profile-icr';
        this._currentObjType = '';
        this._defaultDownloadUrl = 'icr2config/file-storage/img-from-file';
        this._defaultUploadUrl = 'icr2config/file-storage/set-file';
        this._defaultExportUrl = 'icr2config/export/get-token';
        this._defaultUploadArchiveUrl = 'icr2config/export/upload-archive?userid=';
    }

    initRestClient() {
        const settingsRest = {
            authToken: this.authToken,
            user: this,
            logoutUnauthorized: true,
        }

        if (this.getProject()) {
            settingsRest['headers'] = {
                project: this.getProject()
            };
        }

        if (!!this.authToken && !!this.authToken.length) {
            this.restClient = new RestClient(settingsRest);
        }
    }

    async generateFingerprint() {
        const fp = await FingerprintJS.load();
        const result = await fp.get();

        // const visitorId = result.visitorId;
        // cookies.set('fingerPrint', visitorId, {path: '/'});
        // return visitorId;
        return result.visitorId;
    }

    get defaultDownloadUrl() {
        return `${this._defaultDownloadUrl}?project=${this._currentProject}`;
    }

    get defaultUploadArchiveUrl() {
        return this._defaultUploadArchiveUrl;
    }

    get defaultExportUrl() {
        return this._defaultExportUrl;
    }

    get defaultUploadUrl() {
        return `${this._defaultUploadUrl}?project=${this._currentProject}`;
    }

    get fingerPrint() {
        return this._fingerPrint;
    }

    get id() {
        return this._userInfo?.id;
    }

    get userInfo() {
        return this._userInfo;
    }

    set userInfo(value) {
        this._userInfo = value;
        cookies.set('icr2user', value, {path: '/'})
    }

    get authToken() {
        return this._authToken;
    }

    set authToken(value) {
        this._authToken = value;
        cookies.set('authToken', value, {path: '/', maxAge: 86400});
    }

    get currentProject() {
        return this._currentProject;
    }

    set currentProject(value) {
        this._currentProject = value;
        sessionStorage.setItem('icr2config-project', value);
    }

    get twoStepAuth() {
        return this._twoStepAuth;
    }

    set twoStepAuth(value) {
        this._twoStepAuth = value;
        cookies.set('twoStepAuth', this._twoStepAuth, {path: '/', maxAge: 86400});
    }

    loggedIn() {
        return !!this.authToken && this.authToken.length;
    }

    passedTwoStepAuth() {
        // return true;
        return !!this.twoStepAuth && +this.twoStepAuth === 1;
    }

    async login(login, password) {
        const settingsRest = {
            authType: 'Basic',
            authToken: window.btoa(unescape(encodeURIComponent(login + ':' + password))),
            logoutUnauthorized: false
        }

        if (this.getProject()) {
            settingsRest['headers'] = {
                project: this.getProject()
            };
        }

        const tempClient = new RestClient(settingsRest);
        this.authToken = await tempClient.get(`icr2config/login/token?fingerprint=${this.fingerPrint}`, {}, {
            header: 'include'
        });
        this.userInfo = await tempClient.get('icr2config/login/user-info');
        this.initRestClient();
    }

    getProject() {
        return (new Cookies())?.get('services')?.['project'];
    }

    async passTwoStepAuth() {
        this.twoStepAuth = 1;
    }

    async logout() {
        this.authToken = '';
        this.userInfo = '';
        this.twoStepAuth = 0;
        this.goLoginPage();
    }

    get currentObjType() {
        return this._currentObjType;
    }

    set currentObjType(value) {
        this._currentObjType = value;
    }

    goLoginPage() {
        if (!this.authToken.length && window.location.href.indexOf(`/login`) === -1) {
            window.location.href = `/${language}/login`;
        }
    }

    goMainPage() {
        if (this.authToken.length) {
            window.location.href = `/${language}/main`;
        }
    }

    goAuth2Step() {
        this.logout();
    }
}
