const initialState = {
    objClasses: [],
    objTypes: [],
    userModules: [],
    selectedModule: null,
    selectedModuleConfig: [],
    leftPanel: 'configurator',
    attrClasses: [],
    projectList: [],
    projectId: 1,
    userInfo: {}
};

export function common(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case 'COMMON_SET_ATTR_CLASSES':
            return Object.assign({}, state, {
                attrClasses: action.attrClasses
            });
        case 'COMMON_SET_OBJ_CLASSES':
            return Object.assign({}, state, {
                objClasses: action.objClasses
            });
        case 'COMMON_SET_OBJ_TYPES':
            return Object.assign({}, state, {
                objTypes: action.objTypes
            });
        case 'COMMON_SET_PROJECT_LIST':
            return Object.assign({}, state, {
                projectList: action.projectList
            });
        case 'COMMON_SET_PROJECT_ID':
            return Object.assign({}, state, {
                projectId: action.projectId
            });
        case 'COMMON_SET_USER_INFO':
            return Object.assign({}, state, {
                userInfo: action.userInfo
            });
        case 'COMMON_SET_REPORT_LINK':
            return Object.assign({}, state, {
                reportLink: action.reportLink
            });
        case 'COMMON_SET_USER_MODULES':
            return Object.assign({}, state, {
                userModules: action.userModules
            });
        case 'COMMON_SELECT_MODULE':
            return Object.assign({}, state, {
                selectedModule: action.selectedModule
            });
        case 'COMMON_SET_SELECTED_MODULE_CONFIG':
            return Object.assign({}, state, {
                selectedModuleConfig: action.selectedModuleConfig
            });
        case 'COMMON_SET_LEFT_PANEL':
            return Object.assign({}, state, {
                leftPanel: action.leftPanel
            });
        default:
            return state;
    }
}
